.pagination-wrapper {
  font-size: 14px !important;
  float: right;

  .page-link {
    color: #474747;
    transition:0.3s all;

    &:hover{
      background-color:#4fa0ca;
      color:#fff;
    }
  }
}
