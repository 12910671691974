$sidebar-bg-color: #1b2430;

$sidebar-color: #123148;
$highlight-color: #e73d8e;
$icon-bg-color: #1b2430;
$submenu-bg-color: #fff;
$submenu-bg-color-collapsed: #1b2430;
$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

.pro-sidebar {
  width: 220px !important;
  min-width: 220px !important;
  position: fixed !important;
  top: 20px;
  left: 20px;
  height: calc(100vh - 40px) !important;
  z-index: 1021 !important;
  transition: all ease-in 0s !important;

  .pro-menu-item {
    color: #333;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .pro-icon-wrapper {
      margin-right: 0 !important;
    }

    .pro-inner-list-item {
      padding-left: 0 !important;
      background: transparent !important;
    }

    .pro-inner-item {
      padding-top: 5px !important;
      padding-bottom: 0px !important;
      height: 40px;

      &:before {
        display: none !important;
      }

      &:hover {
        color: #4e9fc9 !important;
      }

      a {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0;

        &:hover {
          color: #4e9fc9 !important;
        }
      }

      .icon {
        padding: 0;
        margin-right: 20px;

        img {
          width: 23px;
          height: 23px;
          object-fit: contain;
        }
      }

      a.active {
        background: transparent;
        color: #4e9fc9;
        font-size: 15px;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -30px;
          background-color: #e5f6ff;
          z-index: -1;
          width: 68px;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }
      }

      &:focus {
        color: #570707 !important;
      }
    }

    .pro-inner-list-item {
      color: #4fa0ca;

      .pro-inner-item {
      }
    }
  }

  .pro-sidebar-header {
    display: flex;
    align-items: center;

    h3 {
      color: #123148;
      padding: 10px 0;
      margin: 0;

      img {
        width: 90px;
      }

      .logo-small {
        width: 40px !important;
        padding-top: 8px;
      }
    }
  }

  .pro-sidebar-content {
    height: calc(100% - 110px - 60px);
    overflow: auto;

    .pro-menu {
      padding: 0 30px;
      margin-bottom: 20px;

      .pro-inner-item {
        padding: 0 !important;

        .pro-icon-wrapper {
          width: auto !important;
          height: auto !important;
          min-width: auto !important;
          line-height: initial !important;

          .icon {
            padding: 0;
          }
        }
      }
    }
  }

  .sidebar_footer {
    height: 60px;
    padding: 10px;
    display: flex;
    border-top: 1px solid rgba(0 0 0 / 10%);

    button {
      background: #eaeaea;
      display: flex;
      align-items: center;
      border-radius: 100px;
      cursor: pointer;
      width: 100%;
      outline: none;
      border: none;
    }

    img {
      width: 20px;
      margin-left: 20px;
      opacity: 0.5;
    }
  }
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  height: 110px;
  border: none !important;
  color: #123148;
  padding: 0 30px;
}

.pro-sidebar.collapsed {
  width: 80px !important;
  min-width: 80px !important;
  transition: all ease-in 0s !important;

  .pro-sidebar-header {
    padding: 0 10px !important;

    img {
      width: 100%;
    }
  }

  .pro-sidebar-content {
    .pro-menu {
      padding: 0 10px;

      .pro-sub-menu {
        .pro-inner-item {
          display: grid;
          place-content: center;
        }

        .pro-inner-list-item {
          position: relative !important;
          min-width: unset !important;
          max-width: unset !important;
        }
      }

      // .pro-sub-menu.open {
      //   .pro-inner-list-item {
      //     visibility: visible !important;
      //   }
      // }

      .pro-inner-item {
        .pro-arrow-wrapper {
          display: block !important;
          right: 5px !important;
        }

        a {
          justify-content: center;
        }

        a.active {
          &:after {
            left: -10px;
          }
        }
      }

      .icon {
        margin-right: 0;
      }
    }
  }
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  margin-bottom: 3px;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  display: block !important;
  overflow: hidden !important;
}
.pro-icon-wrapper {
  background: transparent !important;
}
.pro-sidebar > .pro-sidebar-inner {
  color: #fff;
  background: transparent !important;
}

@import "~react-pro-sidebar/dist/scss/styles.scss";
h3.logo-small img {
  width: 51px;
}

@media (max-width: 1025px) {
}

@media (max-width: 767px) {
  // .pro-sidebar{display:none;}
}
