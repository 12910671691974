.navbar {
  height: 70px;
  background-color: #fff;
  box-shadow: -30px 0 0px #ffffff, 20px 0px 0px #fff;
  padding: 10px 0px;
  margin-bottom: 10px;

  .toggle-sidebar-menu {
    cursor: pointer;

    img {
      width: 30px;
    }
  }

  button.back {
    border: none;
    background: transparent;

    img {
      width: 30px;
      opacity: 1;
    }
  }

  button.back.disabled img {
    opacity: 0.2;
  }

  .navbar-nav {
    flex-direction: row;

    li {
      padding-left: 30px;

      &:nth-child(3) {
        padding-left: 10px;
        padding-top: 1px;
      }

      .notification_menu {
        position: relative;

        .badge {
          position: absolute;
          border-radius: 50%;
          font-size: 11px;
          background-color: #4fa0ca;
        }
      }
    }

    li.notification {
      display: flex;
      align-items: center;
    }
  }

  .dropdown-menu-notifications {
    position: absolute !important;
    top: 55px;
    left: -346px;
    width: 400px;
    padding: 15px;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    border-radius: 20px;

    .dropdown-item {
      &:active {
        background-color: #fff !important;
      }
    }

    #notification-img {
      width: 35px;
      height: auto;
    }

    .notification-text {
      white-space: initial;
    }

    .notification-time {
      color: #474747;
      font-size: 12px;
      font-weight: 400;
      margin: 0;
      padding-top: 5px;
    }

    p {
      color: #474747;
      font-size: 15px;
      font-weight: 400;
      margin: 0;

      a {
        color: #474747;
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        float: right;
      }
    }

    .notification_list {
      display: flex;
      align-items: center;

      .message {
        margin-left: 10px;
      }
    }
  }

  .user-shortcut-controls {
    position: absolute !important;
    top: 55px;
    left: -50px;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    border-radius: 20px;

    .dropdown-item {
      font-size: 14px;
      font-weight: 400;
      color: #474747;

      &:active {
        background-color: #fff !important;
      }
    }
  }

  #navbar-user-img {
    display: flex;
    justify-content: flex-end;
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
  }
}
