.add-about-wrapper {
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }

  .cms_box {
    margin: 25px 0px 0px 0;
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    button.submit-btn {
      background-color: #474747 !important;
      border-radius: 50px;
    }
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 450px;
  }

  .submit-btn,
  .add-sub-question-btn {
    color: #fff;
    font-size: 15px;
    padding: 6px 30px;
    margin-top: 20px;

    &:hover {
      background-color: #474747;
    }
  }
}
