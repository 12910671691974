.loader-container {
  position: fixed;
  background: rgba(173, 173, 173, 0.6);
  width: 100%;
  height: 100vh;
  max-height: 100%;
  z-index: 10000 !important;

  img {
    width: 60px;
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.button-loader-container {
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  // width: 100%;
  // height: 100vh;
  // max-height: 100%;
  z-index: 10000 !important;

  img {
    // width: 60px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
