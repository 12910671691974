body {
  /* text-align: center; */
  padding: 40px 0;
  /* background: #fff; */
  font-family: "Roboto", sans-serif;
}
h1 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 20px;
}
p {
  color: #404f5e;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin: 0;
}

.card {
  background: white;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
a.button_blue {
  font-family: "Roboto", sans-serif;
  background: #110b79;
  color: #fff;
  text-decoration: none;
  font-size: 19px;
  padding: 14px 36px;
  display: inline-block;
  margin-top: 35px;
  border-radius: 100px;
}

.success_card{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
