.appointments-wrapper {
  .form-group {
    margin-bottom: 0;
  }
  .search_col input {
    border-radius: 50px;
    background-color: #edf9ff;
    border: 1px solid #e5f6ff;
  }

  .right_col button {
    float: right;
    background-color: #edf9ff;
    border-radius: 50px;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%) !important;
    font-size: 13px;
    font-weight: 600;
    color: #717171;
  }
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }
  .reviewIndication{
    background-color: 'red';
  }

  .appointments {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;
    text-align: Center;
    margin: 25px 0px 0px 0;

    th,
    td {
      font-size: 14px;
      color: #474747;
    }

    i {
      font-size: 16px;
      color: #474747;
    }

    .icon {
      color: #4fa0ca;
    }

    .form-control {
      padding-left: 10px;
      font-size: 14px;
      background-color: #e5f6ff;
      border-radius: 50px;
      border: 1px solid #dedede;
    }

    .appointments-filter-submit-btn {
      background-color: #4fa0ca;
      color: #fff;
      padding: 5px 20px 6px 20px;
      font-size: 14px;

      &:hover {
        background-color: #2e58a6;
      }
    }

    .react-datepicker {
      button {
        outline: none !important;
      }
    }

    .reload-btn {
      margin-left: 20px;
      cursor: pointer;

      .fa-refresh {
        color: #e73d8e;
        margin-top: 7px;
      }
    }
  }
}

.doc-image-bg {
  height: 100vh;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #474747;
}

.Toastify__close-button {
  outline: none !important;
}
