.mx_-10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
thead {
  background-color: #e3f6fb;
}
.mw-130 {
  min-width: 130px;
}

.manage-patient-wrapper {
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }
  .btn-primary {
    border-color: #e73d8e;
  }

  .search_col {
    .form-group {
      margin-bottom: 0;
    }

    input {
      border-radius: 50px;
      background-color: #edf9ff;
      border: 1px solid #e5f6ff;
    }
  }

  .right_col {
    button {
      float: right;
      background-color: #edf9ff;
      border-radius: 50px;
      box-shadow: 0 0 30px rgb(0 0 0 / 3%) !important;
      font-size: 13px;
      font-weight: 600;
      color: #717171;
    }
  }

  .manage-patient {
    margin: 25px 0px 0px 0;
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    h5 {
      font-size: 16px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 20px;
    }

    th,
    td {
      font-size: 14px;
      color: #474747;
      vertical-align: middle;
    }

    .icon {
      color: #4fa0ca;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    a i {
      text-decoration: none;
      color: #474747;
      font-size: 16px;

      &:hover {
        color: #4fa0ca;
      }
    }

    .add-new-patient-btn {
      background-color: #4fa0ca;
      color: #fff;
      padding: 7px 30px;
      font-size: 14px;
      transition: 0.3s all;

      &:hover {
        background-color: #2e58a6;
      }
    }

    h6 {
      font-weight: 400 !important;
    }
  }
}

.patient-details {
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }

  hr {
    margin-bottom: 30px;
  }

  .basic-details {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;
    text-align: Center;

    .details-image {
      width: 170px;
      height: 170px;
      max-width: 100%;
      max-height: 300px;
      border-radius: 50%;
      margin-bottom: 20px;
    }

    h1 {
      font-size: 20px;
      font-weight: 400;
      color: #474747;
    }

    h6 {
      font-size: 14px;
      font-weight: 400;
      color: #474747;
      padding-top: 5px;
      padding-left: 0;

      sup {
        top: unset;
        margin-top: 10px;
        display: inline-block;
        padding: 10px 10px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #474747;
      margin-bottom: 0;
    }
  }

  .personal-details {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    .input-icons {
      position: relative;
    }

    .form-control {
      padding-left: 40px;
      font-size: 15px;
      border-radius: 50px;
      border: 1px solid #f1f1f1;
      height: 45px;
    }

    .icon {
      color: #4fa0ca;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
    }

    h5 {
      font-size: 15px;
      font-weight: 600;
      color: #474747;
      padding-bottom: 0;
      margin-bottom: 25px;
      text-transform: uppercase;
    }

    .update-details-btn {
      font-size: 13px;
      border-radius: 50px;
      padding: 8px 0;
      background-color: #474747;
      border-color: #474747;
      transition: 0.3s all;
      height: 45px;
      text-transform: uppercase;

      &:hover {
        background-color: #474747;
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  .family-members {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px;
    margin-top: 30px;

    th,
    td {
      font-size: 14px;
    }

    img {
      width: 40px;
      border-radius: 50%;
    }

    i {
      font-size: 16px;
      color: #474747;
      padding-right: 14px;
    }

    h5 {
      font-size: 15px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 15px;
    }
  }

  .appointment-history {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;
    margin-top: 30px;

    th,
    td {
      font-size: 14px;
    }

    i {
      font-size: 16px;
      color: #474747;
      padding-right: 14px;
    }

    h5 {
      font-size: 15px;
      font-weight: 600;
      color: #474747;
      padding-bottom: 0;
      margin-bottom: 25px;
      text-transform: uppercase;
    }
  }

  .ratings {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px;
    margin-top: 30px;

    th,
    td {
      font-size: 14px;
    }

    h5 {
      font-size: 15px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 15px;
    }

    a {
      color: #474747;
      text-decoration: none;
    }
  }

  .other-details {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 25px;
    margin-top: 30px;

    h5 {
      font-size: 15px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 15px;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      color: #e73d83;
      background-color: #fff;
      border-color: #e73d8e #e73d8e #fff;
      font-size: 15px;
      padding-top: 8px;
    }

    a {
      color: #474747;
      text-decoration: none;
      background-color: transparent;
    }

    .nav-item {
      font-size: 15px;
    }

    .tab-content-item {
      background-color: #fff;
      color: #474747;
      font-size: 15px;
      padding: 30px 0;

      li {
        font-size: 12px;
        list-style: none;
        display: inline;
        background-color: #e73d83;
        color: #fff;
        padding: 10px 25px 11px 25px;
        border-radius: 3px;
        margin-right: 20px;
      }

      .profile-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }

  .referred-users {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px;
    margin-top: 30px;

    img {
      width: 40px;
      border-radius: 50%;
    }

    th,
    td {
      font-size: 14px;
    }

    i {
      font-size: 16px;
      color: #474747;
      padding-right: 14px;
    }

    h5 {
      font-size: 15px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 15px;
    }
  }
}

.active,
.suspended,
.verified,
.unverified,
.pending,
.confirmed,
.cancelled,
.no-show,
.completed {
  color: #fff;
  background-color: green;
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.pending,
.no-show {
  background-color: rgb(204, 168, 6);
}

.suspended,
.unverified,
.cancelled {
  background-color: #4fa0ca;
}

@media (max-width: 991px) {
  .patient-details {
    .basic-details img.details-image {
      width: 200px !important;
      height: 200px;
    }
  }
}

@media (max-width: 767px) {
  .manage-patient {
    .col-md-5.pr-0 {
      width: auto;
      margin-left: auto;

      input {
        width: 220px;
      }
    }

    .col-md-7.pl-0 {
      width: auto;
      display: inline-block;
      padding: 0;
      float: left;
    }
  }

  .manage-patient-wrapper {
    h5 {
      font-size: 20px;
    }

    .manage-patient {
      padding: 15px 12px;
    }
  }
}

@media (max-width: 575px) {
  .manage-patient {
    .col-md-5.pr-0 {
      margin-top: 15px;
      width: 100%;
      padding-left: 0;

      input {
        width: 100%;
      }
    }
  }
}
